import React, { useState, useEffect } from 'react';
import './Footer.css';
import { useBackground } from '../contexts/BackgroundContext';

function Footer({ isContactPage }) {
  const [time, setTime] = useState('');
  const { toggleBackground, isGradientBackground } = useBackground();

  useEffect(() => {
    const timer = setInterval(() => {
      const date = new Date();
      const options = {
        timeZone: 'America/Los_Angeles',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
      };
      setTime(date.toLocaleTimeString('en-US', options));
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const handleBackgroundToggle = () => {
    toggleBackground();
  };

  return (
    <footer className={`footer ${isContactPage ? 'contact-footer' : ''}`}>
      <div className="location">
        <span className="time">{time}</span>
        <span className="divider"></span>
        <span className="place">los angeles, california</span>
      </div>
      <div className="switch-container">
        <label className="switch">
          <input 
            type="checkbox" 
            checked={isGradientBackground} 
            onChange={handleBackgroundToggle}
          />
          <span className="slider round"></span>
        </label>
      </div>
      <p className="copyright">
        Ojas Nimase © 2024. All rights reserved.
      </p>
    </footer>
  );
}

export default Footer;