import React, { useState, useEffect, lazy, Suspense } from 'react';
import './Contact.css';
import profilePic from '../assets/images/bio.png';
import { FaGithub, FaLinkedin, FaTwitter } from 'react-icons/fa';
import { SiGooglescholar } from 'react-icons/si';

const Header = lazy(() => import('./Header'));
const Footer = lazy(() => import('./Footer'));

function Contact() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="contact-page">
      <div className="contact-content">
        <Suspense>
          <Header isMobile={isMobile} />
        </Suspense>
        <main className="content">
          <div className="pic">
            <div className="pic-image-container">
              <img src={profilePic} alt="Bio" className="pic-image" />
            </div>
            <div className="social-icons">
              <a href="https://github.com/ojuicen" target="_blank" rel="noopener noreferrer">
                <FaGithub />
              </a>
              <a href="https://www.linkedin.com/in/ojasnimase/" target="_blank" rel="noopener noreferrer">
                <FaLinkedin />
              </a>
              <a href="https://scholar.google.com/citations?user=bY5j5mEAAAAJ&hl=en" target="_blank" rel="noopener noreferrer">
                <SiGooglescholar />
              </a>
              <a href="https://twitter.com/ojasnimase" target="_blank" rel="noopener noreferrer">
                <FaTwitter />
              </a>
            </div>
          </div>
          <div className="intro">
            <p>
              You can contact me here: surname [at] usc [dot] edu
            </p>
          </div>
        </main>
        <Suspense>
          <Footer />
        </Suspense>
      </div>
    </div>
  );
}

export default Contact;